import React, { useCallback, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import mq, { mqdown } from '@mq';

import useToggle from '@hooks/useToggle';
import useOutsideClick from '@hooks/useOutsideClick';
import { globalHistory } from '@reach/router';

import LogoSvg from '@vectors/logo.svg';

import MenuSvg from '@vectors/menu.svg';

import scrollTo from '@utils/scrollTo';
import Link from './Link';

const Navigation = ({ showMenu }) => {
  const [expanded, toggleExpanded, close] = useToggle(false);
  const ref = useOutsideClick(close);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          close();
        }
      }),
    [close]
  );

  const scrollToAbout = useCallback(() => {
    close();
    scrollTo('#ueber-uns');
  }, [close]);

  const scrollToFacts = useCallback(() => {
    close();
    scrollTo('#facts-figures');
  }, [close]);

  const scrollToTextSlider = useCallback(() => {
    close();
    scrollTo('#wertversprechen');
  }, [close]);

  const scrollToReferences = useCallback(() => {
    close();
    scrollTo('#stories-referenzen');
  }, [close]);

  const scrollToTeam = useCallback(() => {
    close();
    scrollTo('#team');
  }, [close]);

  const scrollToContact = useCallback(() => {
    close();
    scrollTo('#kontakt');
  }, [close]);

  return (
    <>
      {expanded && <ScrollLock />}
      <Container ref={ref}>
        <Top>
          <HomeLink to="/">
            <Logo />
          </HomeLink>
          <Menu onClick={toggleExpanded} $visible={showMenu} />
        </Top>
        <Links expanded={expanded}>
          <li>
            <SectionLink onClick={scrollToAbout}>Über Uns</SectionLink>
          </li>
          <li>
            <SectionLink onClick={scrollToFacts}>Facts + Figures</SectionLink>
          </li>
          <li>
            <SectionLink onClick={scrollToTextSlider}>Unsere sieben Wertversprechen</SectionLink>
          </li>
          <li>
            <SectionLink onClick={scrollToReferences}>Partner, Referenzen & Mieter</SectionLink>
          </li>
          <li>
            <SectionLink onClick={scrollToTeam}>Team</SectionLink>
          </li>
          <li>
            <SectionLink onClick={scrollToContact}>Kontakt</SectionLink>
          </li>
        </Links>
      </Container>
    </>
  );
};

const ScrollLock = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Container = styled.nav`
  padding: 5.3125rem 1.6875rem 3.625rem 1.6875rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 3;

  ${mq.small} {
    padding: 4.5rem 6.125rem;
  }
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  justify-items: center;
  position: relative;
  z-index: 2;

  ${mq.small} {
    grid-template-columns: 6rem 1fr 6rem;
  }
`;

const HomeLink = styled(Link)`
  grid-column: 2;
`;

const Logo = styled(LogoSvg)`
  ${mqdown.small} {
    width: 7.25rem;
    height: 2.125rem;
  }
`;

const Menu = styled(MenuSvg)`
  grid-column: 3;
  justify-self: end;
  cursor: pointer;

  display: ${props => !props.$visible && 'none'};

  ${mqdown.small} {
    width: 1.6875rem;
    height: 1.125rem;
  }
`;

const Links = styled.ul`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  padding-top: var(--navigation-height);
  padding-bottom: 2rem;
  left: 0;
  font: var(--font-navigation);
  color: var(--color-white);
  display: grid;
  justify-content: center;
  justify-items: center;
  backdrop-filter: blur(1.25rem);

  opacity: ${props => (props.expanded ? 1 : 0)};
  visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
  transition: opacity var(--motion-double), visibility var(--motion-double);
`;

const SectionLink = styled.div`
  cursor: pointer;
`;

export default Navigation;
