/* eslint-disable consistent-return */

function scrollTo(element) {
  let targetElement;

  if (isDomElement(element)) {
    targetElement = element;
  } else if (isString(element)) {
    targetElement = document.querySelector(element);
  } else {
    return;
  }

  if (!targetElement) {
    return;
  }

  targetElement.scrollIntoView({
    behavior: 'smooth',
  });

  return targetElement;
}

function isDomElement(value) {
  return value instanceof Element || value instanceof HTMLDocument;
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export default scrollTo;
