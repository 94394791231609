import React from 'react';
import GlobalStyles from '@styles/GlobalStyles';

import Footer from '@core/Footer';
import Navigation from '@core/Navigation';
import Seo from '@core/Seo';

const Layout = ({ children, location }) => {
  const isHomepage = location.pathname === '/';

  return (
    <>
      <GlobalStyles />
      <Seo />
      <Navigation showMenu={isHomepage} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
