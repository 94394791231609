import { createGlobalStyle } from 'styled-components';

import { layoutWrapper } from '@mixins';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.25;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    /* height: 100%; */
    /* width: 100%; */
    
    -webkit-font-smoothing: antialiased;
    /* ms-overflow-style: none; */
    /* scrollbar-width: none; */
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */

  main, footer, nav {
  }

  main {
  }
  
  section {
    ${layoutWrapper};
  }
`;

export default Base;
