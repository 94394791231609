import React from 'react';
import styled from 'styled-components';
// import { useStaticQuery, graphql } from 'gatsby';

// import { fontLooseM } from '@fonts';
import { grid } from '@mixins';
import mq, { mqdown } from '@mq';
import CoreLink from '@core/Link';

const Footer = () => (
  <Container id="kontakt">
    <Contact>
      <Title>Kontakt</Title>
      <Address>
        <div>Flemings Asset Management GmbH</div>
        <div>Bleichstraße 52</div>
        <div>60313 Frankfurt am Main</div>
      </Address>
      <TelephoneEmail>
        <Link to="tel:+49696605760">T. +49 69 660 576 0</Link>
        <Link to="mailto:info@flemings-asset.com">info@flemings-asset.com</Link>
      </TelephoneEmail>
    </Contact>
    <Legal>
      <Copyright>© Flemings Asset Management GmbH {new Date().getFullYear()}</Copyright>
      <LegalLinks>
        <CoreLink to="/impressum">Impressum</CoreLink>
        <CoreLink to="/datenschutz">Datenschutz</CoreLink>
      </LegalLinks>
    </Legal>
  </Container>
);

const Container = styled.footer`
  background-color: var(--color-dire-wolf);
  color: var(--color-white);
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  align-content: space-between;

  ${mq.small} {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
    row-gap: 12.5rem;
    align-content: end;
  }
`;

const Link = styled(CoreLink)`
  display: block;
`;

const Contact = styled.div`
  ${grid};
  row-gap: 2.625rem;
  font: var(--font-footer);
`;

const Title = styled.div`
  font: var(--font-section-title);
  grid-column: 1 / -1;

  ${mqdown.small} {
    padding-bottom: 1.75rem;
  }

  ${mq.small} {
    grid-column: 2 / 7;
  }
`;

const Address = styled.div`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 7 / 10;
  }
`;

const TelephoneEmail = styled.div`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 10 / -1;
  }
`;

const Legal = styled.div`
  ${grid};
  row-gap: 2.25rem;
  font: var(--font-legal);
`;

const Copyright = styled.div`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 2 / 7;
  }
`;

const LegalLinks = styled.div`
  grid-column: 1 / 3;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: 1rem;

  ${mq.small} {
    grid-column: 7 / 10;
  }
`;

export default Footer;
