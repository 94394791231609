import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    
    --color-dire-wolf: #282828;
    --color-black-olive: #3C3935;
    --color-silver: #D9D9D9;
    --color-alabaster: #EEEDE9;
    --color-tuna: #47484F;

    --color-text: var(--color-black);


    --gutter: 1.25rem; //20
    --grid-gap: 1.25rem; //20
    --grid-column-count: 4;

    ${mq.small} {
      --gutter: 2.5rem; //40
      --grid-gap: 1.25rem; //20
      --grid-column-count: 12;
    }

    ${mq.medium} {
      --gutter: 5rem; //80
    }
    
    ${mq.large} {
      --gutter: 10.25rem; //164
    }

    --navigation-height: 11.0625rem;
    
    ${mq.small} {
      --navigation-height: 12.1875rem;
    }

    --font-family: 'Practice', serif;
    --font-family-sans-serif: 'Diatype', sans-serif;

    --font-navigation: 1.5rem/ 1.2 var(--font-family); //24
    --font-section-title: 1.25rem/ 1.2 var(--font-family-sans-serif); //20
    
    --font-video-text: 3rem/ 1.2 var(--font-family); //48

    --font-about-main: 1.75rem/ 1.21 var(--font-family); //28
    --font-about-more: 1.25rem/ 1.2 var(--font-family); //20
    --font-about-caption: 1rem/ 1.25 var(--font-family-sans-serif); //16
    
    --font-facts-number: 3rem/ 1.2 var(--font-family); //48
    --font-facts-name: 1rem/ 1.25 var(--font-family); //16

    --font-text-slider: 1.25rem/ 1.2 var(--font-family); //20    
    --font-text-slider-index: 2.5rem/ 1.24 var(--font-family); //40    

    --font-references-title: 1.25rem/ 1.5 var(--font-family-sans-serif); //20
    --font-references-subtitle: 1rem/ 1.25 var(--font-family); //16
    --font-references-description: 1rem/ 1.25 var(--font-family); //16

    --font-team-title: 1.25rem/ 1.5 var(--font-family-sans-serif); //20
    --font-team-subtitle: 1rem/ 1.25 var(--font-family); //16
    --font-team-description: 1rem/ 1.25 var(--font-family); //16
    
    --font-legal: 1rem/ 1.25 var(--font-family); //16
    --font-footer: 1.125rem/ 1.22 var(--font-family); //18


    
    ${mq.small} {
      --font-navigation: 4rem/ 1.2 var(--font-family); //64
      --font-section-title: 1.25rem/ 1.25 var(--font-family-sans-serif); //24
      
      --font-about-main: 4rem/ 1.2 var(--font-family); //64
      --font-about-more: 1.3rem/ 1.1875 var(--font-family); //32
      
      --font-facts-number: 4.5rem/ 1.2 var(--font-family); //72
      --font-facts-name: 1.125rem/ 1.22 var(--font-family); //18
      
      --font-text-slider: 3rem/ 1.2 var(--font-family); //48
      --font-text-slider-index: 5.25rem/ 1.25 var(--font-family); //84

      --font-references-title: 1.875rem/ 1.26 var(--font-family-sans-serif); //30
      --font-references-subtitle: 1.875rem/ 1.26 var(--font-family); //30
      --font-references-description: 1.125rem/ 1.22 var(--font-family); //18

      --font-team-title: 1.875rem/ 1.26 var(--font-family-sans-serif); //30
      --font-team-subtitle: 1.125rem/ 1.22 var(--font-family); //18
      --font-team-description: 1rem/ 1.25 var(--font-family); //16
    }

    ${mq.medium} {
      --font-section-title: 1.5rem/ 1.25 var(--font-family-sans-serif); //24
    }
    
    ${mq.large} {
      --font-about-more: 2rem/ 1.1875 var(--font-family); //32
    }
    
    --motion-half: 100ms;
    --motion: 200ms;
    --motion-double: 400ms;
    --motion-triple: 600ms;
    --motion-quadruple: 800ms;
    --motion-quintuple: 1000ms;
  }
`;

export default Vars;
